<template>
  <div
    class="promo-hero-wrapper"
    v-if="
      promoBannerContent &&
      Object.keys(promoBannerContent).length > 0 &&
      unifiedUnixRange.start - nowUtc() < 0 &&
      unifiedUnixRange.end - nowUtc() > 0
    "
  >
    <HeroSection
      class="promo-hero"
      :title="
        promoBannerContent.text && promoBannerContent.text['big-text']
          ? promoBannerContent.text['big-text']
          : ''
      "
      :has-header="true"
      :header-text="
        promoBannerContent.text && promoBannerContent.text['small-text']
          ? promoBannerContent.text['small-text']
          : ''
      "
      :image-src="
        isDesktopOrTablet
          ? promoBannerContent.image.desktop
          : promoBannerContent.image.mobile || '/homepage/hero_banner.png'
      "
      :image-alt="promoBannerContent.image.alt || 'Promo Banner'"
      :link-label="
        promoBannerContent.link && promoBannerContent.link.label
          ? promoBannerContent.link.label
          : ''
      "
      :link-target="
        promoBannerContent.link && promoBannerContent.link.target
          ? promoBannerContent.link.target
          : ''
      "
      :link-url="
        promoBannerContent.link && promoBannerContent.link.url
          ? promoBannerContent.link.url
          : ''
      "
      :full-image-link="true"
    />
  </div>
  <div v-else class="featured" />
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import HeroSection from '~/components/HeroSection.vue';
import { useUser } from '@gemini-vsf/composables';
import { useWindow } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { nowUtc } from '~/helpers/util';

export default defineComponent({
  name: 'PromoBanner',
  methods: { nowUtc },
  components: {
    HeroSection,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    additionalData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { loading: loadingUser, user } = useUser();
    const { isDesktopOrTablet } = useWindow();
    const { locale } = useI18n();

    const promoBannerContent = computed(() =>
      props.data?.it?.active && locale === 'it' ? props.data.it : props.data
    );

    const isValidSegment = computed(() => {
      if (user && !loadingUser && user.value?.segments) {
        return user.value?.segments.some((segment) =>
          props.additionalData.valid_segments.includes(segment)
        );
      }
      return false;
    });

    const unifiedUnixRange = {
      start: props.additionalData?.date_range?.day?.start
        ? Date.parse(
            `${props.additionalData?.date_range?.day?.start?.split('T')[0]}T${
              props.additionalData?.date_range?.time?.start?.split('T')[1]
            }`
          )
        : 100000,
      end: props.additionalData?.date_range?.day?.end
        ? Date.parse(
            `${props.additionalData?.date_range?.day?.end?.split('T')[0]}T${
              props.additionalData?.date_range?.time?.end?.split('T')[1]
            }`
          )
        : 2147483647000,
    };

    return {
      promoBannerContent,
      isValidSegment,
      isDesktopOrTablet,
      unifiedUnixRange,
    };
  },
});
</script>

<style lang="scss" scoped>
@include from-landscape-min {
  .featured {
    column-gap: 1px;
    display: flex;
    .hero {
      width: 50%;
    }
  }
}
</style>

<style lang="scss">
@include from-landscape-min {
  .featured {
    .hero {
      .brand-alert-box {
        min-width: unset;
      }
    }
  }
}
.promo-hero-wrapper {
  .hero.promo-hero {
    min-height: unset;
    margin: 0 auto;
  }
}
</style>
